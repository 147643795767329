import { render, staticRenderFns } from "./CouponsRecord.vue?vue&type=template&id=709cf582&scoped=true&"
import script from "./CouponsRecord.vue?vue&type=script&lang=js&"
export * from "./CouponsRecord.vue?vue&type=script&lang=js&"
import style0 from "./CouponsRecord.vue?vue&type=style&index=0&id=709cf582&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "709cf582",
  null
  
)

export default component.exports