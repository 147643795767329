<template>
  <div class="coupons-record-wrapper">
    <div class="titleBar" v-if="isApp"></div>
    <div class="pageTitle">
      <div class="title_icon" @click="$router.go(-1)">
        <span class="icon-left"></span>
      </div>
      <div class="title_txt">
        <span>领券记录</span>
      </div>
    </div>
    <div class="record-box">
      <div class="record-title">
        <div class="record-coupons-name">{{ couponsName }}</div>
      </div>
      <div class="record-tab">
        <van-tabs v-model="popTabActive">
          <van-tab title="未使用" :name="0"></van-tab>
          <van-tab title="已使用" :name="1"></van-tab>
        </van-tabs>
      </div>
      <div class="record-content">
        <!-- <van-pull-refresh v-model="popRefreshing" @refresh="onPopRefresh">
            <van-list
              v-model="popLoading"
              :finished="popFinished"
              finished-text="没有更多了"
              @load="onPopLoad"
            > -->
        <refresh-list
          :list.sync="popList"
          :requestData="popRequestData"
          @onRequest="onPopRequest"
        >
          <div
            class="coupons-item"
            v-for="(item, index) in showPopList"
            :key="index"
          >
            <div class="item-info">
              <div class="info-left">
                <div class="info-ticketValue">
                  {{ item.ticketValue }}
                  <span>{{ item.type === 1 ? "元" : "%" }}</span>
                </div>
                <div class="info-condValidate">
                  {{ item.condValidate === 0 ? "在场内验证" : "入场前验证" }}
                </div>
              </div>
              <div class="info-right">
                <div class="info-coupons-name">
                  {{ item.plateNumber }}
                </div>
                <div class="info-time">
                  来源：<span>{{ item.source === 1 ? "B端" : "队长端" }}</span>
                </div>
                <div class="info-account">
                  创建者：<span>{{ item.operator }}</span>
                </div>
                <div class="info-time">
                  <p v-if="popTabActive">
                    使用时间：<span>{{ item.modifyTime }}</span>
                  </p>

                  <p v-else>
                    领取时间：<span>{{ item.createTime }}</span>
                  </p>
                </div>
                <!-- <div class="info-parking-name">
                  {{ item.parkingGarageName }}
                </div> -->
              </div>
            </div>
            <!-- <div class="item-num">123 / 123</div> -->
            <!-- <div class="item-note" v-if="item.syncMsg">
              <div class="note-txt">{{ item.syncMsg }}</div>
            </div> -->
          </div>
        </refresh-list>

        <!-- </van-list>
          </van-pull-refresh> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import RefreshList from "../../components/RefreshList.vue";
export default {
  components: {
    RefreshList
  },
  data: () => ({
    popTabActive: 0,
    popRequestData: {},
    popList: [],
    id: "",
    couponsName: ""
  }),

  methods: {
    async onPopRequest(popPageNum) {
      let { data } = await this.$api.getCouponInPlate(this.id, popPageNum);
      this.popRequestData = data;
    }
  },

  computed: {
    showPopList() {
      return this.popList.filter(item => item.used == this.popTabActive);
    },
    ...mapState({
      isApp: state => state.isApp
    })
  },

  created() {
    this.id = this.$route.params.id;
    this.couponsName = this.$route.params.couponsName;
  }
};
</script>

<style lang="less" scoped>
.coupons-record-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 55; /* 上一级页面最高z-index为55 */
}
.record-box {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: #fff;
}

.record-title {
  padding: 10px 20px;
  font-size: 18px;
  display: flex;
  align-items: center;

  .record-coupons-name {
    flex: 1;
  }
}

.record-tab {
  box-shadow: 0 6px 12px -12px #969799;
  border-bottom: 1px solid rgba(7, 17, 27, 0.1);
}

.coupons-item {
  padding: 12px;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 10px;

  &:last-child {
    margin: 0;
  }
}

.item-info {
  display: flex;
  align-items: center;
}

.info-left {
  color: #ee0a24;
  padding: 0 16px;
  text-align: center;

  .info-ticketValue {
    padding: 0 6px;
    margin-bottom: 6px;
    font-size: 30px;

    span {
      font-size: 14px;
    }
  }

  .info-condValidate {
    font-size: 14px;
  }
}

.info-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 6px 0;

  .info-coupons-name {
    font-size: 18px;
    color: #333;
    font-weight: 700;
    margin-bottom: 6px;
  }

  .info-source,
  .info-account,
  .info-time {
    font-size: 14px;

    span {
      color: #333;
    }
  }

  .info-parking-name {
    font-size: 14px;
    color: #000;
  }
}

.item-num {
  font-size: 14px;
  text-align: right;
}

.item-note {
  width: 100%;
  border-top: 1px dashed #ebedf0;
  padding-top: 8px;
  padding-left: 6px;
  margin-top: 8px;

  font-size: 14px;
  display: flex;
  align-items: center;

  .note-txt {
    flex: 1;
    display: flex;

    > div {
      padding: 4px 12px;
      border-radius: 20px;
    }

    .qrcode-btn {
      background-color: #03a89e;
      color: #fff;
      margin-right: 10px;
    }

    .record-btn {
      color: #fff;
      background-color: #1989fa;
    }
  }

  .coupons-num {
    font-size: 14px;
  }
}

.record-content {
  width: 100%;
  flex: 1;
  background-color: #f7f8fa;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 12px;
}
</style>
